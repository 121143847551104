import {
  TSTScraper,
  TCUScraper,
} from '@repo/common/dtos';
import axios from 'axios';

class Scraper {
  baseUrl: string;
  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/scrapper`;
  }

  async scrapeTst(companyId: string): Promise<TSTScraper> {
    const response = await axios.patch<TSTScraper>(`${this.baseUrl}/tst`, { companyId });
    return response.data;
  }

  async scrapeTcu(companyId: string): Promise<TCUScraper> {
    const response = await axios.patch<TCUScraper>(`${this.baseUrl}/tcu`, { companyId });
    return response.data;
  }
}

export default Scraper;
