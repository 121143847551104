import {
  ReceivablesDocumentUploadIn,
  ReceivablesDocumentFilterIn,
  UserReceivablesDocumentsOut,
  ReceivablesDocumentModifyIn,
  ReceivableDocumentTypeOut,
  ReceivablesDocument,
} from '@repo/common/dtos';
import axios, { AxiosResponse } from 'axios';

class Document {
  baseUrl: string;

  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/document-v2`;
  }

  async getDocuments(filter: ReceivablesDocumentFilterIn): Promise<UserReceivablesDocumentsOut> {
    const response = await axios.post<UserReceivablesDocumentsOut>(this.baseUrl, filter);
    return response.data;
  }

  async createWithApplication(document: ReceivablesDocumentUploadIn): Promise<ReceivablesDocument> {
    const response = await axios.put<ReceivablesDocument>(this.baseUrl, document);

    return response.data;
  }

  async createWithCompany(companyId: string, document: ReceivablesDocumentUploadIn): Promise<string> {
    const response = await axios.put<string>(this.baseUrl, {
      ...document,
      companyId,
    });
    return response.data;
  }

  async update(documentPatch: ReceivablesDocumentModifyIn): Promise<string> {
    const response = await axios.patch<string>(`${this.baseUrl}`, documentPatch);

    return response.data;
  }

  async delete(companyId: string, documentId: string): Promise<string> {
    const response = await axios.delete<string>(`${this.baseUrl}/${documentId}`, { data: { companyId } });
    return response.data;
  }

  async markNoDebtDocument(applicationId: string): Promise<string> {
    const response = await axios.post<string>(`${this.baseUrl}/no_debt_document`, { applicationId });
    return response.data;
  }

  async getDocumentList(): Promise<{ documents: ReceivableDocumentTypeOut[] }> {
    const response = await axios.get<{ documents: ReceivableDocumentTypeOut[] }>(`${this.baseUrl}/list`);
    return response.data;
  }

  async updateOcr(gcpFilePath: string, documentType: string, applicationId: string): Promise<string> {
    const response = await axios.patch<string>(`${this.baseUrl}/ocr`, { gcpFilePath, applicationId, documentType });

    return response.data;
  }

  async getDocumentWithProxy(gcpFilePath: string): Promise<string> {
    const response = await axios.get<string>(`${this.baseUrl}/proxy/${gcpFilePath}`);
    return response.data;
  }

  async getPaymentSlipAccessCodes(applicationId: string): Promise<AxiosResponse<Blob>> {
    const response = await axios.get<Blob>(`${this.baseUrl}/payment-slip/access-codes/${applicationId}`, {
      responseType: 'blob',
    });
    return response;
  }
}

export default Document;
