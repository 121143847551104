import axios from 'axios';
import {
  ApplicationFinancialInformationInput, ApplicationFinancialInformationOutput,
  BankCode, LoanPaymentIn, LoanNegotiationIn,
  LoanTransactionChangeIn,
  LoanTransactionInput,
  LoanTransactionOutput,
  LoanInstallmentCalculateOut,
  LoanInstallmentCalculateIn,
} from '../dtos';

class Operation {
  baseUrl: string;

  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/operation`;
  }

  async saveApplicationFinancialInformation(applicationId: string, financial: ApplicationFinancialInformationInput): Promise<ApplicationFinancialInformationOutput> {
    const response = await axios.post<ApplicationFinancialInformationOutput>(`${this.baseUrl}/application/${applicationId}`, financial);
    return response.data;
  }

  async calculateLoanConditions(adminEmail: string, partialFinancial: ApplicationFinancialInformationInput): Promise<ApplicationFinancialInformationOutput> {
    const response = await axios.post<ApplicationFinancialInformationOutput>(`${this.baseUrl}/loan/calculate`, partialFinancial, {
      params: {
        admin_email: adminEmail,
      },
    });
    return response.data;
  }

  async registerPayment(applicationId: string, payment: LoanPaymentIn): Promise<ApplicationFinancialInformationOutput> {
    const response = await axios.post<ApplicationFinancialInformationOutput>(`${this.baseUrl}/application/${applicationId}/payment`, payment);
    return response.data;
  }

  async calculateNegotiationInstallmentAmount(payload: LoanInstallmentCalculateIn): Promise<LoanInstallmentCalculateOut> {
    const response = await axios.post<LoanInstallmentCalculateOut>(`${this.baseUrl}/application/installment/calculate`, payload);
    return response.data;
  }

  async registerNegotiation(applicationId: string, negotiation: LoanNegotiationIn): Promise<ApplicationFinancialInformationOutput> {
    const response = await axios.post<ApplicationFinancialInformationOutput>(`${this.baseUrl}/application/${applicationId}/negotiation`, negotiation);
    return response.data;
  }

  async deleteNegotiation(applicationId: string, negotiationId: string): Promise<string> {
    const response = await axios.delete<string>(`${this.baseUrl}/application/${applicationId}/negotiation/${negotiationId}`);
    return response.data;
  }

  async patchTransaction(applicationId: string, transaction: LoanTransactionChangeIn): Promise<unknown> {
    const response = await axios.patch<unknown>(`${this.baseUrl}/application/${applicationId}/transaction`, transaction);
    return response.data;
  }

  async deleteTransaction(applicationId: string, paymentId: string): Promise<ApplicationFinancialInformationOutput> {
    const response = await axios.delete<ApplicationFinancialInformationOutput>(`${this.baseUrl}/application/${applicationId}/transaction`, {
      params: {
        payment_id: paymentId,
      },
    });
    return response.data;
  }

  async simulateTransaction(applicationId: string, transaction: LoanTransactionInput): Promise<LoanTransactionOutput> {
    const response = await axios.post<LoanTransactionOutput>(
      `${this.baseUrl}/application/${applicationId}/transaction/calculate`,
      transaction,
      {
        params: {
          date_modified: transaction.dateModified,
        },
      },
    );
    return response.data;
  }

  async getBankCodes(): Promise<BankCode[]> {
    const response = await axios.get<BankCode[]>(`${this.baseUrl}/bank-codes`);
    return response.data;
  }
}

export default Operation;
