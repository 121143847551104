import {
  GetReceivablesDocumentOut,
  BorderoOutput,
  ParcelInput,
  ReceivablesCompanyChangeIn,
  BorderoOCROut,
  ApplicationNoteIn,
  ReceivablesApplicationSummary,
  WhatsappMessageIn,
  ChangeApplicationNoteIn,
  ChangeAdminNotificationIn,
  GetAdminNotificationListIn,
  GetAdminNotificationListOut,
  FraudScore,
  WhatsappNotRepliedOut,
  WhatsappBusinessTemplate,
  WhatsappChatFilterOut,
  TenantLeadIn,
  WhatsappConversationStatusListOut,
  MetricsChartDescription,
  MetricsCohortSummary,
  TenantIn,
  Tenant,
  ReceivablesCompany,
  GetAdminRepaymentsCohortsReceivablesAdminMetricsRepaymentsCohortsGetParams,
  GetAdminRepaymentsCohortsSummaryReceivablesAdminMetricsRepaymentsCohortsSummaryGetParams,
  CnpjContractDetailsList,
  ChangeWhatsappConversationIn,
  ApplicationOut,
  NewAdminApplicationIn,
  ApplicationHistory,
  StatusFunnelCountOut,
  FunnelLeadTimeOut,
  CollectionStatusPerformance,
  GetPublicEntityListOut,
  GetPublicEntityReceivablesAdminPublicEntityCnpjGetParams,
  GetPublicEntitiesReceivablesAdminPublicEntityGetParams,
  StatusTrackingHistoryListOut,
  ReceivablesPublicEntityInfoListOut,
  PublicEntityExtendedInfoOut,
  CheckInReport,
  OutboundMarketingFileDataIn,
  OutboundMarketingFileData,
  ReceivablesCompanyLimitIn,
  ReceivablesCompanyLimitOut,
  ReceivablesCompanyCreditAnalysisOut,
  ReceivablesCompanyCreditAnalysisInWithAttachment,
  CollectionPerformanceReport,
  GetLoanPerformanceReceivablesAdminMetricsLoanPerformancePostCondition,
  GetLoanTapeReceivablesAdminMetricsLoanTapeGetFilter,
  LoanTapeEntry,
} from '@repo/common/dtos';
import axios, { AxiosResponse } from 'axios';
import { ChangeTenantLeadIn } from '../dtos/changeTenantLeadIn';
import { GetSalesFunnelReceivablesAdminMetricsSalesFunnelGetParams } from '../dtos/getSalesFunnelReceivablesAdminMetricsSalesFunnelGetParams';
import Funnel from './admin/funnel';
import Operation from './operation';
import { ExportPublicEntitiesReceivablesAdminPublicEntityExportPostParams } from '../dtos/exportPublicEntitiesReceivablesAdminPublicEntityExportPostParams';
import { FullPublicEntity } from '../dtos/fullPublicEntity';
import { GetReceivablesCompanyInfoOutExtended } from '../dtos/getReceivablesCompanyInfoOutExtended';
import { LiquidityReport } from '../dtos/liquidityReport';
import { ReceivablesCompanyInfoListOut } from '../dtos/receivablesCompanyInfoListOut';
import { ReceivableFilterOperationIn } from '../dtos/receivableFilterOperationIn';
import { ReceivablesCompanyInfoOutExtended } from '../dtos/receivablesCompanyInfoOutExtended';
import { StatusFunnelReport } from '../dtos/statusFunnelReport';

class Admin {
  baseUrl: string;
  funnel: Funnel;
  operation: Operation;

  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/admin`;
    this.funnel = new Funnel(this.baseUrl);
    this.operation = new Operation(this.baseUrl);
  }

  async getDocument(gcpPath: string, responseDisposition: string | null = null): Promise<GetReceivablesDocumentOut> {
    const response = await axios.get<GetReceivablesDocumentOut>(`${this.baseUrl}/document`, {
      params: { gcp_path: gcpPath, response_disposition: responseDisposition },
    });
    return response.data;
  }

  async getBordero(applicationId: string): Promise<BorderoOCROut> {
    const response = await axios.get<BorderoOCROut>(`${this.baseUrl}/application/${applicationId}/bordero`);
    return response.data;
  }

  async updateBorderoCalculation(applicationId: string, bordero: ParcelInput): Promise<BorderoOutput> {
    const response = await axios.patch<BorderoOutput>(
      `${this.baseUrl}/application/${applicationId}/bordero/calculate`,
      { parcels: [bordero], userApproved: false },
    );
    return response.data;
  }

  async updateBordero(applicationId: string, bordero: ParcelInput): Promise<BorderoOutput> {
    const response = await axios.patch<BorderoOutput>(
      `${this.baseUrl}/application/${applicationId}/bordero`,
      { parcels: [bordero], userApproved: false },
    );
    return response.data;
  }

  async updateCompany(companyId: string, company: ReceivablesCompanyChangeIn): Promise<string> {
    const response = await axios.patch<string>(
      `${this.baseUrl}/company/${companyId}`,
      company,
    );
    return response.data;
  }

  async createApplication(intakeData: NewAdminApplicationIn): Promise<ApplicationOut> {
    const response = await axios.post<ApplicationOut>(`${this.baseUrl}/application`, intakeData);
    return response.data;
  }

  async addNote(applicationId: string, note: ApplicationNoteIn): Promise<string> {
    const response = await axios.post<string>(
      `${this.baseUrl}/application/${applicationId}/note`,
      note,
    );
    return response.data;
  }

  async updateNote(applicationId: string, note: ChangeApplicationNoteIn): Promise<string> {
    const response = await axios.patch<string>(
      `${this.baseUrl}/application/${applicationId}/note`,
      note,
    );
    return response.data;
  }

  async deleteNote(applicationId: string, noteId: string): Promise<string> {
    const response = await axios.delete<string>(
      `${this.baseUrl}/application/${applicationId}/note/${noteId}`,
    );
    return response.data;
  }

  async getOcrSummary(applicationId: string): Promise<ReceivablesApplicationSummary> {
    const response = await axios.get<ReceivablesApplicationSummary>(`${this.baseUrl}/application/${applicationId}/summary`);
    return response.data;
  }

  async getFraudScore(applicationId: string): Promise<FraudScore> {
    const response = await axios.get<FraudScore>(`${this.baseUrl}/application/${applicationId}/fraud-score`);
    return response.data;
  }

  async sendWhatsappMessage(data: WhatsappMessageIn): Promise<undefined> {
    await axios.post<void>(`${this.baseUrl}/whatsapp/send`, data);
  }

  async addNotification(data: ChangeAdminNotificationIn): Promise<AxiosResponse> {
    return axios.post<void>(`${this.baseUrl}/notification`, data);
  }

  async getWhatsappTemplates(): Promise<WhatsappBusinessTemplate[]> {
    const response = await axios.get<WhatsappBusinessTemplate[]>(`${this.baseUrl}/whatsapp/template`);
    return response.data;
  }

  async getWhatsappCustomTemplates(): Promise<WhatsappBusinessTemplate[]> {
    const response = await axios.get<WhatsappBusinessTemplate[]>(`${this.baseUrl}/whatsapp/custom_template`);
    return response.data;
  }

  async createWhatsappCustomTemplate(data: WhatsappBusinessTemplate): Promise<string[]> {
    const response = await axios.post<string[]>(`${this.baseUrl}/whatsapp/custom_template`, data);
    return response.data;
  }

  async deleteWhatsappCustomTemplate(name: string): Promise<string[]> {
    const response = await axios.delete<string[]>(`${this.baseUrl}/whatsapp/custom_template?name=${name}`);
    return response.data;
  }

  async getNotifications(data: GetAdminNotificationListIn): Promise<GetAdminNotificationListOut> {
    const response = await axios.post<GetAdminNotificationListOut>(`${this.baseUrl}/notification/search`, data);
    return response.data;
  }

  async updateNotification(notificationId: string, data: ChangeAdminNotificationIn): Promise<AxiosResponse> {
    return axios.patch<void>(`${this.baseUrl}/notification/${notificationId}`, data);
  }

  async deleteNotification(notificationId: string): Promise<AxiosResponse> {
    return axios.delete<void>(`${this.baseUrl}/notification/${notificationId}`);
  }

  async getWhatsAppUnreplied(): Promise<WhatsappNotRepliedOut> {
    const response = await axios.get<WhatsappNotRepliedOut>(`${this.baseUrl}/whatsapp/unreplied`);
    return response.data;
  }

  async getWhatsappFilteredChats(desiredFilter: string): Promise<WhatsappChatFilterOut[]> {
    const response = await axios.post<WhatsappChatFilterOut[]>(`${this.baseUrl}/whatsapp/filter`, {
      messageFilter: desiredFilter,
    });
    return response.data;
  }

  async patchMarkAsReplied(data: ChangeWhatsappConversationIn): Promise<AxiosResponse> {
    return axios.patch<void>(`${this.baseUrl}/whatsapp/conversation`, data);
  }

  async getCohortRepaymentChart(params: GetAdminRepaymentsCohortsReceivablesAdminMetricsRepaymentsCohortsGetParams): Promise<MetricsChartDescription> {
    const searchParams = new URLSearchParams();
    if (params.start_date) {
      searchParams.set('start_date', params.start_date);
    }
    if (params.end_date) {
      searchParams.set('end_date', params.end_date);
    }
    const response = await axios.get<MetricsChartDescription>(`${this.baseUrl}/metrics/repayments/cohorts?${searchParams.toString()}`);
    return response.data;
  }

  async getCohortSummary(params: GetAdminRepaymentsCohortsSummaryReceivablesAdminMetricsRepaymentsCohortsSummaryGetParams): Promise<MetricsCohortSummary[]> {
    const searchParams = new URLSearchParams();
    if (params.start_date) {
      searchParams.set('start_date', params.start_date);
    }
    if (params.end_date) {
      searchParams.set('end_date', params.end_date);
    }
    const response = await axios.get<MetricsCohortSummary[]>(`${this.baseUrl}/metrics/repayments/cohorts/summary?${searchParams.toString()}`);
    return response.data;
  }

  async getCohortExport(startDate: string | null, endDate: string | null): Promise<AxiosResponse<Blob>> {
    const response = await axios.post(`${this.baseUrl}/metrics/repayments/cohorts/export`, null, {
      responseType: 'blob',
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response;
  }

  async getApplicationSalesConversion(data: unknown) {
    const response = await axios.post<ApplicationHistory[][]>(`${this.baseUrl}/metrics/application_sales_conversion`, data);
    return response.data;
  }

  async getSalesFunnel(params: GetSalesFunnelReceivablesAdminMetricsSalesFunnelGetParams): Promise<MetricsChartDescription> {
    const searchParams = new URLSearchParams();
    if (params.start_date) {
      searchParams.set('start_date', params.start_date);
    }
    if (params.end_date) {
      searchParams.set('end_date', params.end_date);
    }
    const response = await axios.get<MetricsChartDescription>(`${this.baseUrl}/metrics/sales/funnel?${searchParams.toString()}`);
    return response.data;
  }

  async getConversationStatus(): Promise<WhatsappConversationStatusListOut> {
    const response = await axios.get<WhatsappConversationStatusListOut>(`${this.baseUrl}/whatsapp/conversation-status`);
    return response.data;
  }

  async deleteConversationStatus(status: string): Promise<AxiosResponse> {
    return axios.delete<void>(`${this.baseUrl}/whatsapp/conversation-status?conversation_status=${status}`);
  }

  async createConversationStatus(status: string): Promise<AxiosResponse> {
    return axios.post<void>(`${this.baseUrl}/whatsapp/conversation-status?conversation_status=${status}`);
  }

  async getTenantLeads(): Promise<TenantLeadIn[]> {
    const response = await axios.get<TenantLeadIn[]>(`${this.baseUrl}/tenant/lead`);
    return response.data;
  }

  async createTenant(data: TenantIn): Promise<string> {
    const response = await axios.post<string>(`${this.baseUrl}/tenant`, data);
    return response.data;
  }

  async getTenants(): Promise<Tenant[]> {
    const response = await axios.get<Tenant[]>(`${this.baseUrl}/tenant`);
    return response.data;
  }

  async patchTenant(data: ChangeTenantLeadIn): Promise<string> {
    const response = await axios.patch<string>(`${this.baseUrl}/tenant/lead`, data);
    return response.data;
  }

  async getTransparencyCNPJs(municipalities: string[]): Promise<string[]> {
    const response = await axios.post<string[]>(`${this.baseUrl}/tp-contracts`, { municipalities });
    return response.data;
  }

  async searchTransparencyWithCNPJ(cnpj: string): Promise<CnpjContractDetailsList> {
    const response = await axios.get<CnpjContractDetailsList>(`${this.baseUrl}/tp-contracts/cnpj/${cnpj}`);
    return response.data;
  }

  async getMunicipalities(): Promise<string[]> {
    const response = await axios.get<string[]>(`${this.baseUrl}/tp-contracts/municipalities`);
    return response.data;
  }

  async getBorderoCSV(): Promise<Record<string, string | number>> {
    const response = await axios.get<Record<string, string | number>>(`${this.baseUrl}/bordero/csv`);
    return response.data;
  }

  async getOtmowPhoneNumbers(): Promise<{ phoneNumbers: string[] }> {
    const response = await axios.get<{ phoneNumbers: string[] }>(`${this.baseUrl}/whatsapp/phone_numbers`);
    return response.data;
  }

  async getFunnelStatusCount(startDate: string | null, endDate: string | null, recurrency: 'all' | 'new' | 'recurring' = 'all'): Promise<StatusFunnelReport> {
    const response = await axios.post<StatusFunnelReport>(`${this.baseUrl}/metrics/funnel`, null, {
      params: {
        start_date: startDate,
        end_date: endDate,
        recurrency,
      },
    });
    return response.data;
  }

  async getFunnelExport(startDate: string | null, endDate: string | null): Promise<AxiosResponse<Blob>> {
    const response = await axios.post(`${this.baseUrl}/metrics/funnel/export`, null, {
      responseType: 'blob',
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response;
  }

  async getFunnelLeadTime(startDate: string | null, endDate: string | null, useWorkingDays: boolean, groupByCompany: boolean): Promise<FunnelLeadTimeOut> {
    const response = await axios.post<FunnelLeadTimeOut>(`${this.baseUrl}/metrics/funnel/lead-time`, null, {
      params: {
        start_date: startDate,
        end_date: endDate,
        use_working_days: useWorkingDays,
        group_by_company: groupByCompany,
      },
    });
    return response.data;
  }

  async getFunnelLeadTimeExport(startDate: string | null, endDate: string | null): Promise<AxiosResponse<Blob>> {
    const response = await axios.post(`${this.baseUrl}/metrics/funnel/lead-time/export`, null, {
      responseType: 'blob',
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response;
  }

  async getLoanPerformance(startDate: string | null, endDate: string | null, condition: GetLoanPerformanceReceivablesAdminMetricsLoanPerformancePostCondition): Promise<CollectionPerformanceReport> {
    const response = await axios.post<CollectionPerformanceReport>(`${this.baseUrl}/metrics/loan-performance`, null, {
      params: {
        start_date: startDate,
        end_date: endDate,
        condition,
      },
    });
    return response.data;
  }

  async getLoanPerformanceExport(startDate: string | null, endDate: string | null): Promise<AxiosResponse<Blob>> {
    const response = await axios.post(`${this.baseUrl}/metrics/loan-performance/export`, null, {
      responseType: 'blob',
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response;
  }

  async getLiquidityReport(cnpj: string | null): Promise<LiquidityReport> {
    const response = await axios.get<LiquidityReport>(`${this.baseUrl}/metrics/liquidity`, {
      params: {
        cnpj,
      },
    });
    return response.data;
  }

  async getLiquidityExport(cnpj: string | null): Promise<AxiosResponse<Blob>> {
    const response = await axios.post(`${this.baseUrl}/metrics/liquidity/export`, null, {
      responseType: 'blob',
      params: {
        cnpj,
      },
    });
    return response;
  }

  async getLoanTape(filter: GetLoanTapeReceivablesAdminMetricsLoanTapeGetFilter): Promise<LoanTapeEntry[]> {
    const response = await axios.get<LoanTapeEntry[]>(`${this.baseUrl}/metrics/loan-tape`, {
      params: { filter },
    });
    return response.data;
  }

  async getLoanTapeExport(filter: GetLoanTapeReceivablesAdminMetricsLoanTapeGetFilter): Promise<AxiosResponse<Blob>> {
    const response = await axios.get(`${this.baseUrl}/metrics/loan-tape/export`, {
      responseType: 'blob',
      params: { filter },
    });
    return response;
  }

  async getCheckInReport(startDate: string | null, endDate: string | null): Promise<CheckInReport> {
    const response = await axios.get<CheckInReport>(`${this.baseUrl}/metrics/check-in`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response.data;
  }

  async getPublicEntities(input: GetPublicEntitiesReceivablesAdminPublicEntityGetParams): Promise<GetPublicEntityListOut> {
    const response = await axios.get<GetPublicEntityListOut>(`${this.baseUrl}/public-entity`, {
      params: {
        ...input,
      },
    });
    return response.data;
  }

  async getPublicEntitiesV2(pagination: { page_number: number, items_per_page: number }, filters: ReceivableFilterOperationIn[]): Promise<ReceivablesPublicEntityInfoListOut> {
    const response = await axios.post<ReceivablesPublicEntityInfoListOut>(`${this.baseUrl}/public-entity/v2`, { filters }, { params: { ...pagination } });
    return response.data;
  }

  async getPublicEntity(cnpj: string, query: GetPublicEntityReceivablesAdminPublicEntityCnpjGetParams): Promise<FullPublicEntity> {
    const response = await axios.get<FullPublicEntity>(`${this.baseUrl}/public-entity/${cnpj}`, { params: query });
    return response.data;
  }

  async getPublicEntityV2(cnpj: string): Promise<PublicEntityExtendedInfoOut> {
    const response = await axios.get<PublicEntityExtendedInfoOut>(`${this.baseUrl}/public-entity/v2/${cnpj}`);
    return response.data;
  }

  async getPublicEntitiesExport(params: ExportPublicEntitiesReceivablesAdminPublicEntityExportPostParams): Promise<AxiosResponse<Blob>> {
    const response = await axios.post(`${this.baseUrl}/public-entity/export`, null, {
      responseType: 'blob',
      params,
    });
    return response;
  }

  async downloadAllDocuments(applicationId: string, companyId: string): Promise<AxiosResponse<Blob>> {
    const response = await axios.get(`${this.baseUrl}/documents/download`, {
      responseType: 'blob',
      params: {
        application_id: applicationId,
        company_id: companyId,
        response_disposition: `attachment; filename="DOCS_${applicationId}.zip"`,
      },
    });
    return response;
  }

  async getCompanies(): Promise<ReceivablesCompany[]> {
    const response = await axios.get<ReceivablesCompany[]>(`${this.baseUrl}/company`);
    return response.data;
  }

  async getCompany(id: string): Promise<GetReceivablesCompanyInfoOutExtended> {
    const response = await axios.get<GetReceivablesCompanyInfoOutExtended>(`${this.baseUrl}/company/${id}`);
    return response.data;
  }

  async getApplicationFunnelHistory(applicationId: string): Promise<StatusTrackingHistoryListOut> {
    const response = await axios.get<StatusTrackingHistoryListOut>(`${this.baseUrl}/funnel/application/${applicationId}/tracker`);
    return response.data;
  }

  async getCompaniesReceivables(pagination: { page_number: number, items_per_page: number }, filters: ReceivableFilterOperationIn[]): Promise<ReceivablesCompanyInfoListOut> {
    const response = await axios.post<ReceivablesCompanyInfoListOut>(`${this.baseUrl}/company-receivables`, { filters }, { params: { ...pagination } });
    return response.data;
  }

  async getCompanyReceivables(cnpj: string): Promise<ReceivablesCompanyInfoOutExtended> {
    const response = await axios.get<ReceivablesCompanyInfoOutExtended>(`${this.baseUrl}/company-receivables/${cnpj}`);
    return response.data;
  }

  async setCompanyReceivablesLimit(cnpj: string, limit: ReceivablesCompanyLimitIn): Promise<ReceivablesCompanyLimitOut> {
    const response = await axios.post<ReceivablesCompanyLimitOut>(`${this.baseUrl}/company-receivables/${cnpj}/limit`, limit);
    return response.data;
  }

  async deleteCompanyReceivablesLimit(limitId: string): Promise<string> {
    const response = await axios.delete<string>(`${this.baseUrl}/company-receivables/limit/${limitId}`);
    return response.data;
  }

  async createCompanyReceivablesCreditAnalysis(cnpj: string, analysis: ReceivablesCompanyCreditAnalysisInWithAttachment): Promise<ReceivablesCompanyCreditAnalysisOut> {
    const response = await axios.post<ReceivablesCompanyCreditAnalysisOut>(`${this.baseUrl}/company-receivables/${cnpj}/credit-analysis`, analysis);
    return response.data;
  }

  async deleteCompanyReceivablesCreditAnalysis(analysisId: string): Promise<string> {
    const response = await axios.delete<string>(`${this.baseUrl}/company-receivables/credit-analysis/${analysisId}`);
    return response.data;
  }

  async createOutboundList(payload: OutboundMarketingFileDataIn): Promise<OutboundMarketingFileData> {
    const response = await axios.post<OutboundMarketingFileData>(`${this.baseUrl}/marketing/company_outbound_list`, payload);
    return response.data;
  }
}

export default Admin;
