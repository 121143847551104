/* eslint-disable max-classes-per-file */

'use client';

import { VerifyOut, IntakeIn, AuthOut, TenantLeadIn, DailyStandupPointChangeIn, DailyStandupReportUserListOut } from '@repo/common/dtos';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { type User } from 'firebase/auth';
import Receivables from './receivables';
import { ReceivablesIntakeInfoIn } from '../dtos/receivablesIntakeInfoIn';

export class OtmowService {
  baseUrl: string;
  receivables: Receivables;
  authInterceptor?: number;
  token?: string;

  constructor() {
    this.baseUrl = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8000';
    this.receivables = new Receivables(this.baseUrl);
  }

  static setInterceptor(interceptor: (config: InternalAxiosRequestConfig) => Promise<InternalAxiosRequestConfig>) {
    axios.interceptors.request.use(interceptor);
  }

  handleAuthChange(user: User) {
    if (typeof this.authInterceptor === 'number') {
      axios.interceptors.request.eject(this.authInterceptor);
    }

    this.authInterceptor = axios.interceptors.request.use(async (_config) => {
      const token = await user.getIdToken();
      const config = { ..._config }; // Create a copy of the config object
      config.headers['id-token'] = token;
      this.token = token;
      return config;
    });
  }

  async verify(): Promise<VerifyOut> {
    const response = await axios.get<VerifyOut>(`${this.baseUrl}/verify`);
    return response.data;
  }

  async waitlist(data: object): Promise<string> {
    const response = await axios.post<string>(`${this.baseUrl}/waitlist`, data);
    return response.data;
  }

  async preliminaryIntake(data: ReceivablesIntakeInfoIn): Promise<string> {
    const response = await axios.post<string>(`${this.baseUrl}/receivables/intake-preliminary`, data);
    return response.data;
  }

  async intake(data: IntakeIn): Promise<AuthOut> {
    const response = await axios.post<AuthOut>(`${this.baseUrl}/receivables/intake`, data);
    return response.data;
  }

  async twoFactorAuth(whatsappNumber: string, cnpj?: string): Promise<string> {
    const payload = cnpj ? { whatsappNumber, cnpj } : { whatsappNumber };
    const response = await axios.post<string>(`${this.baseUrl}/receivables/2fa/whatsapp`, payload);
    return response.data;
  }

  async tenantLead(data: TenantLeadIn): Promise<unknown> {
    const response = await axios.post<unknown>(`${this.baseUrl}/receivables/tenant/lead`, data);
    return response.data;
  }

  async login(whatsappNumber: string, code: string): Promise<AuthOut> {
    const response = await axios.post<AuthOut>(`${this.baseUrl}/receivables/login`, { whatsappNumber, code });
    return response.data;
  }

  async getDailyStandupPoints(): Promise<DailyStandupReportUserListOut> {
    const response = await axios.get<DailyStandupReportUserListOut>(`${this.baseUrl}/daily-standup/points`);
    return response.data;
  }

  async setDailyStandupPoints(input: DailyStandupPointChangeIn): Promise<void> {
    await axios.patch<void>(`${this.baseUrl}/daily-standup/points`, input);
  }
}

const defaultService = new OtmowService();

export default defaultService;
