import Admin from './admin';
import Document from './document';
import Representative from './representative';
import Contract from './contract';
import Tenant from './tenant';
import Scraper from './scraper';

class Receivables {
  baseUrl: string;
  representative: Representative;
  document: Document;
  admin: Admin;
  contract: Contract;
  tenant: Tenant;
  scraper: Scraper;

  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/receivables`;
    this.representative = new Representative(this.baseUrl);
    this.document = new Document(this.baseUrl);
    this.admin = new Admin(this.baseUrl);
    this.contract = new Contract(this.baseUrl);
    this.tenant = new Tenant(this.baseUrl);
    this.scraper = new Scraper(this.baseUrl);
  }
}

export default Receivables;
